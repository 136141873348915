import { Select, SelectProps } from "antd";
import React from "react";
import { CHILDREN_ACTIVITY_TYPE } from "../../../constants/constant";

type optionType = {
    label: string;
    value: number;
};

const ActivityTypeLookup = (props: SelectProps) => {
    return (
        <Select
            {...props}
            style={{ width: "150px" }}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            allowClear
        >
            {CHILDREN_ACTIVITY_TYPE?.map((data: optionType) => {
                return (
                    <Select.Option key={data?.value} value={data.value}>
                        {data?.label}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
export default ActivityTypeLookup;
