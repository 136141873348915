import ApiService from "../util/api.service";
import { SERVICE_CONFIG_URLS } from "./config";
import utilService from "../util/utils.service";

const ChildrenActivityService = {
    addChildrenActivity,
    updateChildrenActivity,
    getActivityCategory,
    deleteChildrenActivity,
    getChildrenActivity
};

async function getChildrenActivity(id) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.CHILDREN_ACTIVITY.ACTIVITY, { id });
    const response = await ApiService.get(url);
    return response;
}
async function addChildrenActivity(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.CHILDREN_ACTIVITY.ADD_CHILD_ACTIVITY, payload);
    return response;
}
async function updateChildrenActivity(payload) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.CHILDREN_ACTIVITY.ACTIVITY, { id: payload.id });
    const response = await ApiService.put(url, payload.body);
    return response;
}
async function getActivityCategory() {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.CHILDREN_ACTIVITY.ACTIVITY_CATEGORY);
    return response;
}
async function deleteChildrenActivity(id) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.CHILDREN_ACTIVITY.DELETE_CHILDREN_ACTIVITY, { id });
    const response = await ApiService.delete(url);
    return response;
}

export default ChildrenActivityService;
