import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

import { API_STORAGE_KEY } from "./../../../constants/constant";
import LookUpApiService from "./../../../ApiServices/lookup.services";
import notificationService from "./../../../util/notification.service";

const getTimezone = async () => {
    const { ok, data, response } = await LookUpApiService.getTimezone();
    if (ok) {
        return data;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const TimeZoneLookup = (props) => {
    const { data: timeZones = [], isLoading } = useQuery(API_STORAGE_KEY.TIMEZONE_LOOKUP, getTimezone);

    return (
        <>
            <Select
                {...props}
                disabled={isLoading}
                loading={isLoading}
                value={isLoading ? undefined : props.value}
                className="time-zone"
                showSearch
                filterOption={(input, option) => {
                    return option.props.children.toLowerCase().includes(input.toLowerCase());
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
                {timeZones?.map((data, index) => {
                    return (
                        <Select.Option key={data?.id} value={data.id}>
                            {data?.name}
                        </Select.Option>
                    );
                })}
            </Select>
        </>
    );
};
TimeZoneLookup.defaultProps = {};
export default TimeZoneLookup;
