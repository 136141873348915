import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

import { API_STORAGE_KEY } from "../../../constants/constant";
import { RecommendConnectService } from "../../../ApiServices";
import notificationService from "../../../util/notification.service";

const getRecommendConnects = async () => {
    const { ok, data, response } = await RecommendConnectService.getConnectCategories();

    if (ok) {
        const mutatedData = data?.categories?.map((item) => {
            return {
                ...item,
                value: item.id,
                label: item.name
            };
        });
        return mutatedData;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const RecommendConnectLookup = (props) => {
    const { iscustomizewidth = true } = props;

    const { data: recommends = [], isLoading } = useQuery(API_STORAGE_KEY.CONNECT_CATEGORY, getRecommendConnects, {});

    return (
        <>
            <Select
                {...props}
                showSearch
                filterOption={(input, option) => {
                    return option.props.children.toLowerCase().includes(input.toLowerCase());
                }}
                style={{ ...(iscustomizewidth && { width: "150px" }) }}
                allowClear
                loading={isLoading}
                disabled={isLoading}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
                {recommends?.map((data) => {
                    return (
                        <Select.Option key={data?.id} value={data.id}>
                            {data?.name}
                        </Select.Option>
                    );
                })}
            </Select>
        </>
    );
};
export default RecommendConnectLookup;
