import ApiService from "../util/api.service";
import { SERVICE_CONFIG_URLS } from "./config";

const AnalyticsService = {
    getUsersData,
    getParentVisits,
    getTimeSpentApp,
    getUserStatistic,
    getUserLocReports,
    getSearchStatistic,
    getTimeSpentSection,
    getContentStatistics,
    getPushNotificationApp,
    getParentVisitsSection,
    getTotalUsersNeighborhood
};

async function getUsersData() {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.GET_USERS_DATA);
    return response;
}

async function getContentStatistics(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.GET_CONTENT_STATISTICS, payload);
    return response;
}

async function getParentVisitsSection(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ANALYTICS.PARENTS_VISIT_SECTION, payload);
    return response;
}
async function getTimeSpentSection(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ANALYTICS.TIME_SPENT_SECTION, payload);
    return response;
}
async function getParentVisits(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.GET_PARENTS_VISITS, payload);
    return response;
}
async function getTimeSpentApp(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.TIME_SPENT_APP, payload);
    return response;
}
async function getPushNotificationApp(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.PUSH_NOTIFICATION, payload);
    return response;
}

async function getTotalUsersNeighborhood(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.TOTAL_USERS_NEIGHBORHOOD, payload);
    return response;
}
async function getUserLocReports(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.USER_LOCATIONS_REPORT, payload);
    return response;
}

async function getUserStatistic(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.USER_STATISTIC, payload);
    return response;
}

async function getSearchStatistic(payload) {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ANALYTICS.SEARCH_STATISTIC, payload);
    return response;
}

export default AnalyticsService;

