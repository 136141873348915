import ApiService from "../util/api.service";

import { SERVICE_CONFIG_URLS } from "./config";
import utilService from "./../util/utils.service";

const AccountService = {
    getProfileDetail,
    updateUserProfile,
    toggleAccountStatus
};

async function getProfileDetail(userId) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.ACCOUNT.GET_PROFILE_DETAIL, userId);
    const response = await ApiService.get(url);
    return response;
}

async function updateUserProfile(payload) {
    const response = await ApiService.put(SERVICE_CONFIG_URLS.ACCOUNT.UPDATE_PROFILE_DETAIL, payload);
    return response;
}

async function toggleAccountStatus(payload) {
    const response = await ApiService.put(SERVICE_CONFIG_URLS.ACCOUNT.TOGGLE_ACCOUNT_STATUS, payload);
    return response;
}

export default AccountService;
