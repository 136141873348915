import React from "react";
import { useQuery } from "react-query";
import { Select } from "antd";
import { LookUpApiService } from "../../../ApiServices";
import { API_STORAGE_KEY, ISD_STATUS } from "../../../constants/constant";
import notificationService from "../../../util/notification.service";

const getIsdOptions = async () => {
    const { ok, data, response } = await LookUpApiService.getIsds({ status: ISD_STATUS.ALL });
    if (ok) {
        return data;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const IsdLookup = (props) => {
    const { data: isds = [], isLoading } = useQuery(API_STORAGE_KEY.ISD_LOOKUP, getIsdOptions);

    return (
        <Select
            {...props}
            showSearch
            filterOption={(input, option) => {
                return option.props.children.toLowerCase().includes(input.toLowerCase());
            }}
            allowClear
            loading={isLoading}
            disabled={isLoading}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
            {isds?.map((data) => {
                return (
                    <Select.Option key={data?.id} value={data.id}>
                        {data?.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
export default IsdLookup;
