import React from "react";
import { useQuery } from "react-query";
import { Checkbox, Skeleton, Col, Row } from "antd";

import { LookUpApiService } from "../../../ApiServices";
import { API_STORAGE_KEY } from "../../../constants/constant";
import notificationService from "../../../util/notification.service";
import "../index.less";

const getTransportation = async () => {
    const { ok, data, response } = await LookUpApiService.getTransportation();
    if (ok) {
        return data;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const TransportationLookup = (props) => {
    const { data: transportationData = [], isLoading } = useQuery(
        API_STORAGE_KEY.TRANSPORTATION_LOOKUP,
        getTransportation
    );

    return (
        <Skeleton active loading={isLoading}>
            <Checkbox.Group defaultValue={props.checked} {...props}>
                <Row gutter={[16, 8]} style={{ display: "flex", flexDirection: "row" }}>
                    {transportationData?.map((info) => {
                        return (
                            <Col className="checkbox-options">
                                <Checkbox value={info.id} key={info?.id}>
                                    {info?.name}
                                </Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            </Checkbox.Group>
        </Skeleton>
    );
};
export default TransportationLookup;
