import { Col, Menu, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import { MAIN_SIDE_BAR_OPTIONS } from "../../constants/Roles";
import { NAV_STYLE_MINI_SIDEBAR, THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { MailOutlined, AppstoreOutlined, SettingOutlined } from "@ant-design/icons";
import { AUTHENTICATED_ROUTES } from "../../routes/constant";
import { LogoutModal } from "../../components";
import utilService from "../../util/utils.service";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";

const SidebarContent = () => {
    let { themeType, pathname, navStyle } = useSelector(({ settings }) => settings);
    let sideBarOptions = MAIN_SIDE_BAR_OPTIONS["Admin"];
    const { SubMenu, Item } = Menu;
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const renderSideBarOptions = (props) => {
        if (!props.sideBarOptions) {
            return;
        }
        return (
            <>
                {sideBarOptions.map((singleSidebarOption, indexOne) => {
                    return (
                        <React.Fragment key={indexOne}>
                            {singleSidebarOption.subMenu ? (
                                <SubMenu
                                    key={singleSidebarOption.selectedOptionKey}
                                    icon={<i className={`gx-text-white icon ${singleSidebarOption.icon} `} />}
                                    title={
                                        <span className="noirProRegular gx-text-white">{singleSidebarOption.text}</span>
                                    }
                                >
                                    {Array.isArray(singleSidebarOption.subMenu) &&
                                        singleSidebarOption.subMenu.map((singleSubMenu, indexTwo) => {
                                            return (
                                                <Item className="child-menu" key={singleSubMenu.selectedOptionKey}>
                                                    <Link
                                                        to={singleSubMenu.linkTo}
                                                        className="noirProRegular gx-text-white"
                                                    >
                                                        <Row align="middle" className="gx-m-0">
                                                            <Col className="gx-ml-4">
                                                                <span>{singleSubMenu.text}</span>
                                                            </Col>
                                                            <Col>
                                                                <i className={`icon ${singleSubMenu.icon} `} />{" "}
                                                            </Col>
                                                        </Row>
                                                    </Link>
                                                </Item>
                                            );
                                        })}
                                </SubMenu>
                            ) : (
                                <Item key={singleSidebarOption.selectedOptionKey}>
                                    <Link to={singleSidebarOption.linkTo} className="noirProRegular">
                                        <Row align="middle" className="gx-m-0">
                                            <Col>
                                                <i className={`icon ${singleSidebarOption.icon} `} />
                                            </Col>
                                            <Col>
                                                <span>{singleSidebarOption.text}</span>
                                            </Col>
                                        </Row>
                                    </Link>
                                </Item>
                            )}
                        </React.Fragment>
                    );
                })}
                <Menu.Item key="logout">
                    <span onClick={() => setShowLogoutModal(!showLogoutModal)} className="noirProRegular gx-text-white">
                        <i className="icon usd-logout" />
                        <span>Logout</span>
                    </span>
                </Menu.Item>
            </>
        );
    };

    let selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[0];

    return (
        <>
            {showLogoutModal && (
                <LogoutModal
                    visible={showLogoutModal}
                    onCancel={() => {
                        setShowLogoutModal(false);
                    }}
                    onSuccess={() => {
                        setShowLogoutModal(false);
                    }}
                />
            )}

            <SidebarLogo />
            <div className="gx-sidebar-content sidebar-scroll custom-sidebar-scroll">
                <div
                    className={`gx-sidebar-notifications ${navStyle === NAV_STYLE_MINI_SIDEBAR && "avatar-container"}`}
                >
                    <UserProfile />
                </div>

                <Menu
                    defaultOpenKeys={[defaultOpenKeys]}
                    selectedKeys={[selectedKeys]}
                    theme={themeType === THEME_TYPE_LITE ? "light" : "dark"}
                    mode="inline"
                    className="custom-sidebar-scroll side-menu"
                >
                    {renderSideBarOptions({ sideBarOptions: sideBarOptions })}
                </Menu>
            </div>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;
