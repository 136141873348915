import React from "react";
import { Avatar } from "antd";

import utilService from "../../util/utils.service";
import { useQueryClient } from "react-query";
import { API_STORAGE_KEY } from "./../../constants/constant";

const UserProfile = () => {
    const queryClient = useQueryClient();
    const userInfo = queryClient.getQueryData(API_STORAGE_KEY.USER_DETAILS);

    return (
        <div className="gx-align-items-center custom-avatar-uniquesoftwaredev-row noirProRegular">
            <Avatar
                src={userInfo?.profilePicture?.viewableLink ? userInfo?.profilePicture?.viewableLink : null}
                className="gx-size-40 gx-pointer custom-avatar-uniquesoftwaredev"
            >
                {!userInfo?.profilePicture?.viewableLink && (
                    <span>{`${userInfo?.firstName?.trim()[0]?.toUpperCase()}${userInfo?.lastName
                        ?.trim()[0]
                        ?.toUpperCase()}`}</span>
                )}
            </Avatar>
            <span className="gx-avatar-name gx-ml-3">
                <span>
                    {`${utilService.capitalizedString(userInfo?.firstName, "")} ${utilService.capitalizedString(
                        userInfo?.lastName,
                        ""
                    )}`}
                </span>
            </span>
        </div>
    );
};

export default UserProfile;
