import {
  ACTIVITY_STATUS,
  ACTIVITY_TYPE,
  CONTENT_TYPE,
  DASHBOARD_DATE_TYPE,
  RECOMMEND_CONNECT_STATUS,
  USER_STATUS,
  USER_STATUS_ACTIONS,
  CONNECTED_TYPE,
  CONDITIONAL_NOTIFICATION_TYPE,
  REDIRECTION_TYPE,
  AGE_GROUPS,
  REPORT_DATE_TYPE,
  ANALYTICS_SECTIONS,
} from "./constantTypes";
import {AUTHENTICATED_ROUTES} from "../routes/constant";

import {ReactComponent as Printer} from "../assets/images/printer-icon.svg";
import {ReactComponent as Party} from "../assets/images/party-icon.svg";
import {ReactComponent as Recipe} from "../assets/images/recipe-icon.svg";
import {ReactComponent as Craft} from "../assets/images/craft-icon.svg";
import {ReactComponent as Game} from "../assets/images/game-icon.svg";
import {ReactComponent as Emblem} from "../assets/images/icon-20-emblem-blue.svg";
import {ReactComponent as Users} from "../assets/images/users-blue.svg";

export const PAGINATION_CONSTANT = {
  PAGE_SIZE: 10,
  PAGE_NUMBER: 1,
};

export const PAGINATION_COUNT = 5;

export const tableSort = {
  ASCENDING: "ascend",
  DESCENDING: "descend",
};

export const DESIGNATION_PAGINATION_CONSTANT = {
  PAGE_SIZE: 5,
  PAGE_NUMBER: 1,
};

export const pageProperties = {
  WIDTH: 1024,
  OVERFLOW_Y: 250,
};

export const STORAGE_CONST = {
  TOKEN: "TOKEN",
  EXTERNAL_TOKEN: "EXTERNAL_TOKEN",
  THEME_TYPE: "theme_type",
  USER_INFO: "USER_INFO",
  NEIGHBORHOOD_LOOKUP: "NEIGHBORHOOD_LOOKUP",
};

export const supportedFileFormat = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/jfif",
  ".svg",
  "image/svg+xml",
  "application/pdf",
];
export const supportedImageFormat = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/jfif",
];

export const TOPIC_LOOKUP_TYPE = {
  ALL_NEIGHORHOOD: "2",
  CHAT_TOPICS: "1",
};

export const API_STORAGE_KEY = {
  VIEW_ACTIVITY: "VIEW_ACTIVITY",
  VIEW_ACTIVITY_LIST: "VIEW_ACTIVITY_LIST",
  GENERAL_TAG_LOOKUP: "GENERAL_TAG_LOOKUP",
  ACCOMMODATION_LOOKUP: "ACCOMMODATION_LOOKUP",
  TRANSPORTATION_LOOKUP: "TRANSPORTATION_LOOKUP",
  NEIGHBORHOOD_LOOKUP: "NEIGHBORHOOD_LOOKUP",
  GENERAL_TAG_KEY: "GENERAL_TAG",
  ACCOMMODATION_KEY: "ACCOMMODATION",
  TRANSPORTATION_KEY: "TRANSPORTATION",
  FREEBIE_CATEGORY: "FREEBIE_CATEGORY",
  REPORTED_FREEBIES: "REPORTED_FREEBIES",
  USER_DETAILS: "USER_DETAILS",
  CATEGORY_LIST: "CATEGORY_LIST",
  ACCESS_LIST: "ACCESS_LIST",
  ACCESS_CATEGORY_LIST: "ACCESS_CATEGORY_LIST",
  EDUCATION_SUPPORT: "EDUCATION_SUPPORT",
  EDUCATION_SUPPORT_LIST: "EDUCATION_SUPPORT_LIST",
  EDUCATION_SUPPORT_CATEGORY_LIST: "EDUCATION_SUPPORT_CATEGORY_LIST",
  EXPLORE_LIST: "EXPLORE_LIST",
  ACCESS_LOOKUP: "ACCESS_LOOKUP",
  EXPLORE_LOOKUP: "EXPLORE_LOOKUP",
  CHAT_POST: "CHAT_POST",
  CHAT_COMMENT: "CHAT_COMMENT",
  REPLIES_DATA: "REPLIES_DATA",
  SUB_REPLIES_DATA: "SUB_REPLIES_DATA",
  PROFILE_INFO: "PROFILE_INFO",
  TIMEZONE_LOOKUP: "TIMEZONE_LOOKUP",
  CHAT_TOPICS: "CHAT_TOPICS",
  CHAT_ALL_TOPICS: "CHAT_ALL_TOPICS",
  SUGGESTED_TOPICS: "SUGGESTED_TOPICS",
  TOPIC_LOOKUP: "TOPIC_LOOKUP",
  ISD_LOOKUP: "ISD_LOOKUP",
  NOTIFICATION_LOOKUP: "NOTFICATION_LOOKUP",
  PARENT_INTEREST_LOOKUP: "PARENT_INTEREST_LOOKUP",
  CHILD_INTEREST_LOOKUP: "CHILD_INTEREST_LOOKUP",
  RECOMMEND_CONNECT: "RECOMMEND_CONNECT",
  RECOMMEND_CONNECT_LOOKUP: "RECOMMEND_CONNECT_LOOKUP",
  CHILD_SPECIAL_NEEDS: "CHILD_SPECIAL_NEEDS",
  SEARCH_ACTIVITIES: "SEARCH_ACTIVITIES",
  SEARCH_FREEBIE: "SEARCH_FREEBIE",
  SEARCH_CHAT: "SEARCH_CHAT",
  SEARCH_EXPLORE: "SEARCH_EXPLORE",
  SEARCH_ACCESS: "SEARCH_ACCESS",
  ACCESS: "ACCESS",
  ANNOUNCEMENT: "ANNOUNCEMENT",
  GET_CATEGORY_MEDIA: " GET_CATEGORY_MEDIA",
  CONNECT_CATEGORY: "CONNECT_CATEGORY",
  ACTIVITY_CATEGORIES: "ACTIVITY_CATEGORIES",
  ACTIVITY_LIST: "ACTIVITY_LIST",
  ARTICLE: "ARTICLE",
  COMMON: "COMMON",
  ANALYTIC: "ANALYTIC",
};

export const RESOURCE_TYPE = {
  PARENT: 0,
  PDF: 1,
  LINK: 2,
};

export const CATEGORY_TYPE = {
  CATEGORY: {
    name: "Category",
    value: 1,
  },
  SUB_CATEGORY: {
    name: "Sub Category",
    value: 2,
  },
};

export const CATEGORY_RESOURCE_TYPE = {
  LINK: {
    name: "Link",
    value: RESOURCE_TYPE.LINK,
  },
  PDF: {
    name: "PDF",
    value: RESOURCE_TYPE.PDF,
  },
};

export const LOGOUT_KEYS = [
  STORAGE_CONST.TOKEN,
  STORAGE_CONST.USER_INFO,
  STORAGE_CONST.EXTERNAL_TOKEN,
];

export const USER_ROLES = {
  Administrator: {text: "Administrator", value: 1},
  Moderator: {text: "Moderator", value: 2},
};

export const ADMIN_ROLE = [
  {text: "Administrator", value: 1},
  {text: "Moderator", value: 2},
];

export const APP_NAME = "THE BEST PLACE FOR KIDS";
export const APP_NAME_MINI = "Parent Pass";
export const FOOTER_TEXT_APP_NAME =
  "Copyright 2022 - Parent Pass®. In collaboration with Unique Software Development.";

export const THEME = {
  LITE: "light",
  DARK: "dark",
};

export const DASHBOARD_TAB_STATUS = [
  {
    title: "Post & Comments",
  },
  {
    title: "Events",
  },
  {
    title: "Freebies",
  },
];

export const QUIZ_STATUS = {
  Invalid: "Invalid",
  Rejected: "Rejected",
  Incomplete: "Incomplete",
  Approved: "Approved",
};

export const AccountStatus = {
  Disabled: "Disabled",
  Inactive: "Inactive",
  Invited: "Invited",
  Active: "Active",
};

export const SORT_ORDER = {
  ASC: "ascend",
  DESC: "descend",
};

export const SORT_ORDER_VALUE = {
  ascend: "ASC",
  descend: "DESC",
};

export const exportTypes = {
  CSV: "csv",
};

export const IMAGE_VALIDATION_LIMIT = {
  LIMIT: "You have reached the maximum images uploaded!",
};

export const COLORS = {
  CARTESIAN_GRID_GREY: "#EBEBEB",
  NEIGHBORHOOD_GREEN: "#039E8B",
  ACTIVITIES_PINK: "#F09EAA",
  CHAT_GREEN: "#96C762",
  FREEBIES_PURPLE: "#9983BE",
  ORGANIZATION_ORANGE: "#FFC34D",
  GUEST_BLUE: "#177EBC",
  FREEBIES_DARK_BLUE: "#08445F",
  ARTICLE_ORANGE: "#FF7C32",
  ACCESS_BLUE: "#28AAE1",
  PUSH_RED: "#F34029",
  CRIME_BLUE: "#34718D",
  FIND_BLUE: "#607cf7",
};

export const STATUS_INFO = [
  {
    id: ACTIVITY_STATUS.PENDING,
    name: "Pending",
  },
  {
    id: ACTIVITY_STATUS.ACTIVE,
    name: "Approved",
  },
  {
    id: ACTIVITY_STATUS.DECLINED,
    name: "Rejected",
  },
];

export const RECOMMEND_STATUS_INFO = [
  {
    id: RECOMMEND_CONNECT_STATUS.APPROVED,
    name: "Published",
  },
  {
    id: RECOMMEND_CONNECT_STATUS.PENDING,
    name: "Need Review",
  },
  {
    id: RECOMMEND_CONNECT_STATUS.REJECTED,
    name: "Rejected",
  },
];

export const DASHBOARD_DATE_FILTER = [
  {
    id: DASHBOARD_DATE_TYPE.ALL_TIME,
    name: "All Time",
  },
  {
    id: DASHBOARD_DATE_TYPE.WEEKLY,
    name: "Week",
  },
  {
    id: DASHBOARD_DATE_TYPE.MONTHLY,
    name: "Month",
  },
];

export const REPORTS_DATE_FILTER = [
  {
    id: REPORT_DATE_TYPE.ALL_TIME,
    name: "All Time",
  },
  {
    id: REPORT_DATE_TYPE.TODAY,
    name: "Today",
  },
  {
    id: REPORT_DATE_TYPE.YESTERDAY,
    name: "Yesterday",
  },

  {
    id: REPORT_DATE_TYPE.THIS_WEEK,
    name: "This Week",
  },
  {
    id: REPORT_DATE_TYPE.LAST_WEEK,
    name: "Last Week",
  },
  {
    id: REPORT_DATE_TYPE.THIS_MONTH,
    name: "This Month",
  },
  {
    id: REPORT_DATE_TYPE.LAST_MONTH,
    name: "Last Month",
  },
  {
    id: REPORT_DATE_TYPE.Q1_THIS_YEAR,
    name: "Q1 This Year",
  },
  {
    id: REPORT_DATE_TYPE.Q2_THIS_YEAR,
    name: "Q2 This Year",
  },
  {
    id: REPORT_DATE_TYPE.Q3_THIS_YEAR,
    name: "Q3 This Year",
  },
  {
    id: REPORT_DATE_TYPE.Q4_THIS_YEAR,
    name: "Q4 This Year",
  },
  {
    id: REPORT_DATE_TYPE.Q1_LAST_YEAR,
    name: "Q1 Last Year",
  },
  {
    id: REPORT_DATE_TYPE.Q2_LAST_YEAR,
    name: "Q2 Last Year",
  },
  {
    id: REPORT_DATE_TYPE.Q3_LAST_YEAR,
    name: "Q3 Last Year",
  },
  {
    id: REPORT_DATE_TYPE.Q4_LAST_YEAR,
    name: "Q4 Last Year",
  },
  {
    id: REPORT_DATE_TYPE.THIS_YEAR,
    name: "This Year",
  },
  {
    id: REPORT_DATE_TYPE.LAST_YEAR,
    name: "Last Year",
  },
  // {
  //     id: REPORT_DATE_TYPE.CUSTOM_RANGE,
  //     name: "Custom Range"
  // }
];

export const sectionsData = [
  {
    id: ANALYTICS_SECTIONS.EVENTS,
    name: "Events",
  },
  {
    id: ANALYTICS_SECTIONS.ACTIVITIES,
    name: "Activities",
  },
  {
    id: ANALYTICS_SECTIONS.POST,
    name: "Chat",
  },
  {
    id: ANALYTICS_SECTIONS.FREEBIE,
    name: "Freebies",
  },
  {
    id: ANALYTICS_SECTIONS.ORGANIZATION,
    name: "Connect",
  },
  {
    id: ANALYTICS_SECTIONS.ARTICLE,
    name: "Article",
  },
  {
    id: ANALYTICS_SECTIONS.ANNOUNCEMENT,
    name: "Announcement",
  },
];

export const ADMINISTRATOR_STATUS_INFO = [
  {
    id: ACTIVITY_STATUS.ACTIVE,
    name: "Active",
    value: 300,
  },
  {
    id: ACTIVITY_STATUS.INACTIVE,
    name: "Inactive",
    value: 200,
  },
];

export const USER_STATUS_INFO = [
  {
    id: USER_STATUS.PENDING,
    name: "Pending",
    value: 1,
  },
  {
    id: USER_STATUS.ACTIVE,
    name: "Active",
    value: 2,
  },
  {
    id: USER_STATUS.BANNED,
    name: "Banned",
    value: 3,
  },
];

export const ACTIVITY_LIST = [
  {
    id: ACTIVITY_TYPE.ALL,
    name: "All",
    value: 0,
  },
  {
    id: ACTIVITY_TYPE.EVENT,
    name: "Event",
    value: 1,
  },
  {
    id: ACTIVITY_TYPE.PROGRAM,
    name: "Program",
    value: 0,
  },
];

export const CONTENT_LIST = [
  {
    id: CONTENT_TYPE.ALL,
    name: "All",
    value: 0,
  },
  {
    id: CONTENT_TYPE.POST,
    name: "Post",
    value: 1,
  },
  {
    id: CONTENT_TYPE.COMMENT,
    name: "Comment",
    value: 0,
  },
];

export const ACTIVITY_INFO = [
  {
    id: ACTIVITY_TYPE.EVENT,
    name: "Event",
  },
  {
    id: ACTIVITY_TYPE.PROGRAM,
    name: "Program",
  },
];

export const CONDITIONAL_NOTIFICATION = [
  {
    label: "Yes",
    value: CONDITIONAL_NOTIFICATION_TYPE.YES,
  },
  {
    label: "No",
    value: CONDITIONAL_NOTIFICATION_TYPE.NO,
  },
];

export const NOTIFICATION_LANGUAGE = [
  {
    label: "English",
    value: 1,
  },
  {
    label: "Spanish",
    value: 2,
  },
  {
    label: "English & Spanish",
    value: 3,
  },
];

export const CONNECTED_INFO = [
  {
    id: CONNECTED_TYPE.EMAIL,
    name: "Internal",
  },
  {
    id: CONNECTED_TYPE.EXTERNAL,
    name: "Social",
  },
  {
    id: CONNECTED_TYPE.GUEST,
    name: "Guest",
  },
];

export const CONNECT_TYPE_LABEL = {
  [CONNECTED_TYPE.EMAIL]: "Internal",
  [CONNECTED_TYPE.EXTERNAL]: "Social",
  [CONNECTED_TYPE.GUEST]: "Guest",
};

export const CC_INFO = [
  {
    id: 1200,
    name: "CHIP",
  },
  {
    id: 1300,
    name: "STAR",
  },
  {
    id: 1400,
    name: "STAR Kids",
  },
];

export const AGE_RANGE = [
  {value: AGE_GROUPS.ANY, label: "Any"},
  {value: AGE_GROUPS.BABIES, label: "0-2 years"},
  {value: AGE_GROUPS.TODDLER, label: "3-5 years"},
  {value: AGE_GROUPS.ELEMENTARY_SCHOOL, label: "6-9 years"},
  {value: AGE_GROUPS.MIDDLE_SCHOOL, label: "10-14 years"},
  {value: AGE_GROUPS.HIGH_SCHOOL, label: "15-18 years"},
];
export const WEEK = [
  {label: "Sun", id: 0},
  {label: "Mon", id: 1},
  {label: "Tue", id: 2},
  {label: "Wed", id: 3},
  {label: "Thu", id: 4},
  {label: "Fri", id: 5},
  {label: "Sat", id: 6},
];

export const IMAGE_SUPPORTED_FORMAT = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/svg",
  "images/jfif",
  "application/pdf",
];

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

export const EXTERNAL_TOKEN =
  "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6WyJjb21tdW5pdHkucGFydG5lckBtYWlsaW5hdG9yLmNvbSIsImEwODA1ZTViLTQ1YzktNDM2ZC04YmQ3LWRiY2VkZDA3NzUxOSJdLCJuYW1laWQiOlsiYTBkM2NkOTgtZjZiNi00YWYxLTkxYzEtNjMxYWM2MTkzMDQyIiwiYTBkM2NkOTgtZjZiNi00YWYxLTkxYzEtNjMxYWM2MTkzMDQyIl0sInJvbGUiOiJDb21tdW5pdHkgUGFydG5lciIsIm5iZiI6MTY0Nzk2Mjc1NiwiZXhwIjoxNjQ3OTY2MzU2LCJpYXQiOjE2NDc5NjI3NTYsImlzcyI6Iklzc3VlciIsImF1ZCI6IkF1ZGllbmNlIn0.8ZdzmkShSZrPmkaf7YGtqWlOApRaiAF3uU6C_InKD4PG5h0u6e-WRNQoCFasBg5nfGoYCib9JA0x0QAq0RO0dw";

export const OTHER_OPTION = "Other";
export const CONTENT_VIOLATION_ERROR =
  "Your content violates our system rules.";
export const DAYS_OF_WEEK = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const EVENT_RECURRENCE_TYPE = {
  NONE: 0,
  SIMPLE: 1,
  COMPLEX: 2,
};
export const YEAR_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT = "MM/DD/YYYY";
export const DATE_TIME_FORMAT = "MM/DD/YYYY, hh:mm a";
export const MAX_FILE_SIZE = 6;

export const DELETE_EVENT_TYPE = {
  SERIES: 1,
  SINGLE: 2,
};

export const TRANSPORTATION_TYPE_ID = {
  BUS: 1,
  EMBLEM: 2,
};

export const TAB_STATUS = {
  Post: "Post & Comments",
  Activities: "Activities",
  Freebies: "Freebies",
};

export const TOP_BAR_TITLE = {
  [AUTHENTICATED_ROUTES.DASHBOARD]: "Dashboard",
  [AUTHENTICATED_ROUTES.EVENTS]: "Events",
  [AUTHENTICATED_ROUTES.REPORTED_EVENTS]: "Reported Events",
  [AUTHENTICATED_ROUTES.MY_PROFILE]: "My Profile",
  [AUTHENTICATED_ROUTES.FREEBIE_CATEGORY]: "Categories",
  [AUTHENTICATED_ROUTES.REPORTED_FREEBIES]: "Reported Freebies",
  [AUTHENTICATED_ROUTES.ADD_INTERNAL_ACTIVITY]: "Add Internal Activity",
  [AUTHENTICATED_ROUTES.REPORTED_CONTENT]: "Reported Content",
  [AUTHENTICATED_ROUTES.CHAT_MANAGEMENT]: "Topics",
};

export const LANGUAGE_ID = {
  ENGLISH: 1,
  SPANISH: 2,
};

export const ISD_STATUS = {
  ALL: 1,
  PUBLIC: 2,
  PRIVATE: 3,
};

export const CONTACT_TYPE = {
  PHONE: 1,
  WEB: 2,
  TEXT: 3,
  EMAIL: 4,
};

export const CONTACT_INFO = [
  {
    id: CONTACT_TYPE.PHONE,
    name: "Phone",
  },
  {
    id: CONTACT_TYPE.WEB,
    name: "Web",
  },
  {
    id: CONTACT_TYPE.TEXT,
    name: "Text",
  },
  {
    id: CONTACT_TYPE.EMAIL,
    name: "Email",
  },
];

export const LINK_TYPE_OPTIONS = [
  {
    id: 1,
    value: "PDF",
  },
  {
    id: 2,
    value: "LINK",
  },
];

export const IN_APP_TAB_IDS = {
  EVENT: REDIRECTION_TYPE.ACTIVITY,
  FREEBIE: REDIRECTION_TYPE.FREEBIE,
  CHAT: 300,
  EXPLORE: REDIRECTION_TYPE.EXPLORE,
  ACCESS: REDIRECTION_TYPE.ACCESS,
  FIND: REDIRECTION_TYPE.FIND,
  OTHER: 600,
};

export const IN_APP_REDIRECTION_TABS = {
  [IN_APP_TAB_IDS.EVENT]: {
    id: IN_APP_TAB_IDS.EVENT,
    name: "Event",
  },
  [IN_APP_TAB_IDS.FREEBIE]: {
    id: IN_APP_TAB_IDS.FREEBIE,
    name: "Freebie",
  },
  [IN_APP_TAB_IDS.CHAT]: {
    id: IN_APP_TAB_IDS.CHAT,
    name: "Chat",
  },
  [IN_APP_TAB_IDS.EXPLORE]: {
    id: IN_APP_TAB_IDS.EXPLORE,
    name: "Explore",
  },
  [IN_APP_TAB_IDS.FIND]: {
    id: IN_APP_TAB_IDS.FIND,
    name: "Find",
  },
  // [IN_APP_TAB_IDS.ACCESS]: {
  //     id: IN_APP_TAB_IDS.ACCESS,
  //     name: "Access"
  // },

  [IN_APP_TAB_IDS.OTHER]: {
    id: IN_APP_TAB_IDS.OTHER,
    name: "Other Screens",
  },
};

export const URL_DATA = {
  EVENT_URL:
    "https://prodparentpassstorage.blob.core.windows.net/pp-resourcecontainer/Media/Avatars/event@3x.png?sv=2020-04-08&st=2022-03-30T12%3A30%3A48Z&se=3022-03-31T12%3A30%3A00Z&sr=b&sp=r&sig=beEMzsU%2F9hs%2FP%2FpKu5zhAWuXcrNZA6WID1XpSw07LhM%3D",
  PROGRAM_URL:
    "https://prodparentpassstorage.blob.core.windows.net/pp-resourcecontainer/Media/Avatars/program.jpg?sv=2020-04-08&st=2022-03-30T12%3A30%3A35Z&se=3022-03-31T12%3A30%3A00Z&sr=b&sp=r&sig=SddBqQbPoDA984j0tewv0%2FtoWtZGmthhqVcVDpEiwus%3D",
};

export const CONTENT_STATISTICS = [
  {
    key: "noOfPosts",
    label: "Chat Posts",
    color: COLORS.CHAT_GREEN,
    icon: "usd-chat",
  },
  {
    key: "noOfEvents",
    label: "Events",
    color: COLORS.NEIGHBORHOOD_GREEN,
    icon: "usd-activities",
  },
  {
    key: "noOfPrograms",
    label: "Programs",
    color: COLORS.ORGANIZATION_ORANGE,
    icon: "usd-programs",
  },
  {
    key: "noOfOrganizations",
    label: "Connect",
    color: COLORS.GUEST_BLUE,
    icon: "usd-organizations",
  },
  {
    key: "noOfFreebies",
    label: "Freebies",
    color: COLORS.FREEBIES_DARK_BLUE,
    icon: "usd-freebies",
  },
  {
    key: "noOfArticles",
    label: "Articles",
    color: COLORS.ARTICLE_ORANGE,
    icon: "usd-articles",
  },
  {
    key: "noOfAccess",
    label: "Access",
    color: COLORS.ACCESS_BLUE,
    icon: "usd-resources",
  },
  {
    key: "noOfActivities",
    label: "Explore",
    color: COLORS.GUEST_BLUE,
    icon: "usd-connect",
  },
  {
    key: "noOfAnnoucements",
    label: "Announcements",
    color: COLORS.ACTIVITIES_PINK,
    icon: "usd-announcements",
  },
  {
    key: "noOfPushNotifications",
    label: "Push Notif.",
    color: COLORS.PUSH_RED,
    icon: "usd-push",
  },
];

export const TOTAL_USERS_WITH_CHILD = {
  babies: {
    label: "Babies",
    color: COLORS.CHAT_GREEN,
    icon: "usd-babies",
  },
  toddlers: {
    label: "Toddlers",
    color: COLORS.NEIGHBORHOOD_GREEN,
    icon: "usd-toddlers",
  },
  elementarySchool: {
    label: "Elementary School",
    color: COLORS.ORGANIZATION_ORANGE,
    icon: "usd-elementary",
  },
  middleSchool: {
    label: "Middle School",
    color: COLORS.GUEST_BLUE,
    icon: "usd-middle-school",
  },
  highSchool: {
    label: "High School",
    color: COLORS.FREEBIES_DARK_BLUE,
    icon: "usd-high-school",
  },
};

export const STATISTICS_CARD = [
  {
    key: "noOfGuests",
    label: "Guests",
    description: "Users",
    color: "pp-text-dark-blue",
    icon: "usd-guests",
  },
  {
    key: "noOfRegistered",
    label: "Registered",
    description: "Users",
    color: "pp-text-blue",
    icon: "usd-administrators",
  },
  {
    key: "totalUsers",
    label: "All Users",
    description: "Guest + Registered",
    color: "pp-text-green",
    icon: "usd-users",
  },

  {
    key: "newUsers",
    data: [
      {
        description: "Daily",
        color: "pp-text-green",
        key: "daily",
      },
      {
        description: "Weekly",
        color: "pp-text-dark-blue",
        key: "weekly",
      },
      {
        description: "Monthly",
        color: "pp-text-blue",
        key: "monthly",
      },
    ],
    label: "New Users",
    description: "Guest + Registered",
    color: "pp-text-green",
    icon: "usd-users",
    hideIcon: true,
  },

  {
    key: "activeUsers",
    data: [
      {
        description: "Daily",
        color: "pp-text-green",
        key: "daily",
      },
      {
        description: "Weekly",
        color: "pp-text-dark-blue",
        key: "weekly",
      },
      {
        description: "Monthly",
        color: "pp-text-blue",
        key: "monthly",
      },
    ],
    label: "Active Total Users",
    description: "Guest + Registered",
    color: "pp-text-green",
    icon: "usd-users",
    hideIcon: true,
  },
  {
    key: "avgOnboardingTime",
    label: "Onboarding",
    description: "Avg. Time Spent",
    color: "pp-text-green",
    icon: "usd-clock",
  },
  {
    key: "totalSearches",
    label: "Total Searches",
    description: "All Sections",
    color: "pp-text-pink",
    icon: "usd-search",
  },
  {
    key: "avgAppActivityTime",
    label: "App Activity",
    description: "Avg. Time Online",
    color: "pp-text-cyan",
    icon: "usd-account",
  },
];

export const CHILDREN_ACTIVITY_TIME_FRAME = [
  {value: 1, label: "05 Minutes"},
  {value: 2, label: "10 Minutes"},
  {value: 3, label: "20 Minutes"},
  {value: 4, label: "30 Minutes"},
  {value: 5, label: "60 Minutes"},
  {value: 6, label: "Varies"},
];

export const CHILDREN_ACTIVITY = {
  CRAFT: 1,
  PRINTABLE: 2,
  GAME: 3,
  RECIPE: 4,
  PARTY: 5,
  EDUCATION: 6,
  PARENTING: 7,
};

export const COMPONENT_TYPE = {
  [CHILDREN_ACTIVITY.CRAFT]: "Materials",
  [CHILDREN_ACTIVITY.GAME]: "Materials",
  [CHILDREN_ACTIVITY.RECIPE]: "Ingredients",
  [CHILDREN_ACTIVITY.PARTY]: "Planning Timelines",
};

export const CHILDREN_ACTIVITY_TYPE = [
  {value: CHILDREN_ACTIVITY.CRAFT, label: "Crafts & Experiments", icon: Craft},
  {
    value: CHILDREN_ACTIVITY.PRINTABLE,
    label: "Coloring Pages & Printables",
    icon: Printer,
  },
  {value: CHILDREN_ACTIVITY.GAME, label: "Games & Apps", icon: Game},
  {value: CHILDREN_ACTIVITY.RECIPE, label: "Recipes", icon: Recipe},
  {value: CHILDREN_ACTIVITY.PARTY, label: "Birthday Parties", icon: Party},
  {value: CHILDREN_ACTIVITY.EDUCATION, label: "Education", icon: Emblem},
  {value: CHILDREN_ACTIVITY.PARENTING, label: "Parenting", icon: Users},
];

export const CONTENT_OBJ_VALUES = {
  [CHILDREN_ACTIVITY.CRAFT]: {
    name: CHILDREN_ACTIVITY_TYPE[CHILDREN_ACTIVITY.CRAFT - 1].label,
    values: [],
  },
  [CHILDREN_ACTIVITY.PRINTABLE]: {
    name: CHILDREN_ACTIVITY_TYPE[CHILDREN_ACTIVITY.PRINTABLE - 1].label,
    values: [],
  },
  [CHILDREN_ACTIVITY.GAME]: {
    name: CHILDREN_ACTIVITY_TYPE[CHILDREN_ACTIVITY.GAME - 1].label,
    values: [],
  },
  [CHILDREN_ACTIVITY.RECIPE]: {
    name: CHILDREN_ACTIVITY_TYPE[CHILDREN_ACTIVITY.RECIPE - 1].label,
    values: [],
  },
  [CHILDREN_ACTIVITY.PARTY]: {
    name: CHILDREN_ACTIVITY_TYPE[CHILDREN_ACTIVITY.PARTY - 1].label,
    values: [],
  },
  [CHILDREN_ACTIVITY.EDUCATION]: {
    name: CHILDREN_ACTIVITY_TYPE[CHILDREN_ACTIVITY.EDUCATION - 1].label,
    values: [],
  },
  [CHILDREN_ACTIVITY.PARENTING]: {
    name: CHILDREN_ACTIVITY_TYPE[CHILDREN_ACTIVITY.PARENTING - 1].label,
    values: [],
  },
};

export const ADD_ACTIVITY_INITIAL_VALUES = {
  components: [""],
  hasPlanningTimeline: true,
  activityType: null,
  image: [],
};

export const SECTION_INITIAL_VAL = {
  title: "",
  activityType: null,
  sectionContents: [],
  contentObj: CONTENT_OBJ_VALUES,
};

export const PAYLOAD_KEY = {
  OFFSET: "Pagination.Offset",
  COUNT: "Pagination.Count",
  SORT_NAME: "sort.name",
  SORT_ORDERBY: "sort.orderBy",
};
