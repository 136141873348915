import { useQuery } from "react-query";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal, Space, Input, Radio, Pagination, Skeleton, Col, Row, Empty } from "antd";

import ViewFreebie from "../../Freebie/ViewFreebie";
import { CustomTabs } from "../../../../components";
import { useNotificationInAppUri } from "../queries";
import { ContentDrawer } from "../../Chat/ContentDrawer";
import { LookUpApiService } from "../../../../ApiServices";
import useDebounce from "../../../../custom-hooks/useDebounce";
import ViewActivity from "../../Activities/ViewActivity/Index";
import { RenderDrawer, Search } from "./NotificationUriComponents";
import useGridRequest from "../../../../custom-hooks/useGridRequest";
import notificationService from "../../../../util/notification.service";
import { DATE_TIME_FORMAT, formatDateToSpecificTZ, formatDateToSpecificTZv2 } from "../../../../util/date-time.service";
import { FindTabData, getSearchApiMethod, keyInfo, OtherTabData, titleInfo, typeInfo } from "../helper";
import {
    API_STORAGE_KEY,
    IN_APP_TAB_IDS,
    IN_APP_REDIRECTION_TABS,
    CHILDREN_ACTIVITY_TYPE
} from "../../../../constants/constant";

import "./index.less";

const INITIAL_PAYLOAD = {
    searchText: "",
    Offset: 0,
    Count: 5
};

const InAppNotificationURI = ({ visible = false, onCancel, form }) => {
    const debounceRef = useRef();
    const [payload, setPayload] = useState(INITIAL_PAYLOAD);
    const [selectedData, setSelectedData] = useState(null);
    const [showDrawer, setShowDrawer] = useState({
        visible: false,
        type: IN_APP_TAB_IDS.EVENT
    });
    const [selectedTab, setSelectedTab] = useState(IN_APP_TAB_IDS.CHAT);
    const isEventKey = selectedTab == IN_APP_TAB_IDS.EVENT;
    const isFreebieKey = selectedTab == IN_APP_TAB_IDS.FREEBIE;
    const isChatKey = selectedTab == IN_APP_TAB_IDS.CHAT;
    const isExploreKey = selectedTab == IN_APP_TAB_IDS.EXPLORE;
    const isAccessKey = selectedTab == IN_APP_TAB_IDS.ACCESS;

    const debouncedSearch = useDebounce(payload.searchText, 500);

    useEffect(() => {
        if ((!payload.searchText && selectedData) || !payload.searchText) {
            setSelectedData(null);
        }
    }, [payload.searchText]);

    useEffect(() => {
        setSelectedTab(IN_APP_TAB_IDS.CHAT);
    }, [visible]);
    const {
        data,
        dataUpdatedAt,
        error,
        errorUpdatedAt,
        failureCount,
        isError,
        isFetched,
        isFetchedAfterMount,
        isFetching,
        isIdle,
        isLoading,
        isLoadingError,
        isPlaceholderData,
        isPreviousData,
        isRefetchError,
        isRefetching,
        isStale,
        isSuccess,
        refetch,
        remove,
        status
    } = useNotificationInAppUri({
        onSuccess: () => {
            setSelectedData(null);
        },
        payload: {
            offset: payload.Offset,
            searchText: debouncedSearch,
            count: payload.Count
        },
        explorePayload: { pagination: { offset: payload.Offset, count: payload.Count }, searchBy: debouncedSearch },
        selectedTab,
        visible
    });

    const handlePagination = (page, pageSize) => {
        setPayload((prev) => ({
            ...prev,
            Offset: pageSize * (page - 1)
        }));
    };
    const handleFieldValue = () => {
        form.setFieldsValue({
            title: titleInfo(selectedTab, selectedData),
            type: typeInfo(selectedTab),
            activityDayId: selectedData?.activityDayId,
            freebieId: selectedData?.freebieId,
            postId: selectedData?.postId,
            exploreId: selectedData?.id,
            accessId: selectedData?.accessId,
            redirectionType: selectedData?.redirectionType
        });
        handleCancel();
    };
    const onOpen = () => {
        setShowDrawer((val) => ({
            visible: true,
            type: IN_APP_REDIRECTION_TABS[selectedTab]["id"]
        }));
    };
    const onClose = () => {
        setShowDrawer((val) => ({
            visible: false,
            type: null
        }));
    };

    const handleCancel = () => {
        setSelectedData(null);
        setSelectedTab(IN_APP_TAB_IDS.CHAT);
        setPayload(INITIAL_PAYLOAD);
        onCancel();
    };

    return (
        <>
            <RenderDrawer
                showDrawer={showDrawer}
                onOpen={onOpen}
                onClose={onClose}
                selectedData={selectedData}
                selectedTab={selectedTab}
            />
            <div
                onClick={() => {
                    setSelectedData(null);
                }}
            >
                <Modal
                    centered
                    keyboard={false}
                    footer={false}
                    width={900}
                    onCancel={handleCancel}
                    destroyOnClose={true}
                    visible={visible}
                    className="add-topic-modal"
                    title="Add Link"
                >
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedData(null);
                        }}
                    >
                        <div className="gx-profile-content">
                            <CustomTabs
                                data={Object.values(IN_APP_REDIRECTION_TABS)?.sort((a, b) =>
                                    a.name.localeCompare(b.name)
                                )}
                                defaultActiveKey={String(IN_APP_TAB_IDS.CHAT)}
                                onChange={(selectedTab) => {
                                    setSelectedTab(selectedTab);
                                    setSelectedData(null);
                                }}
                            />
                        </div>

                        {selectedTab != IN_APP_TAB_IDS.OTHER && selectedTab != IN_APP_TAB_IDS.FIND && (
                            <Search
                                placeholder=""
                                value={payload?.searchText}
                                onChange={({ target: { value } }) =>
                                    setPayload((prev) => ({
                                        ...prev,
                                        searchText: value
                                    }))
                                }
                            />
                        )}
                        {selectedTab == IN_APP_TAB_IDS.FIND && (
                            <div
                                className="card-container"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedData(null);
                                }}
                            >
                                {Object.values(FindTabData)
                                    ?.sort((a, b) => (b.name > a.name ? 1 : -1))
                                    ?.map((info, index) => {
                                        return (
                                            <div
                                                className="card-body find-card-body"
                                                tabIndex={index}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedData(info);
                                                }}
                                            >
                                                <img src={info?.icon} alt="" />
                                                <p className="card-description">{info?.description}</p>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                        {selectedTab == IN_APP_TAB_IDS.OTHER && (
                            <div
                                className="card-container"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedData(null);
                                }}
                            >
                                {Object.values(OtherTabData)
                                    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                                    ?.map((info, index) => {
                                        return (
                                            <div
                                                className="card-body"
                                                tabIndex={index}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedData(info);
                                                }}
                                            >
                                                <p className="card-heading">{info?.name}</p>
                                                <img src={info?.icon} alt="" />
                                                <p className="card-description">{info?.description}</p>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}

                        {selectedTab != IN_APP_TAB_IDS.OTHER && (
                            <Skeleton active loading={isLoading || isFetching}>
                                {data?.list?.length > 0 && payload.searchText ? (
                                    <Radio.Group
                                        className="radio-container"
                                        onChange={({ target: { value } }) => {
                                            setSelectedData(value);
                                        }}
                                        value={selectedData}
                                    >
                                        {data?.list?.map((activity) => {
                                            let dateTime = formatDateToSpecificTZ(
                                                activity?.startDate,
                                                DATE_TIME_FORMAT.US_DATE_TIME_FORMAT
                                            );
                                            let postedDate = formatDateToSpecificTZ(
                                                activity?.contentCreatedOn,
                                                DATE_TIME_FORMAT.US_DATE_TIME_FORMAT
                                            );
                                            return (
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Radio
                                                        value={activity}
                                                        id={activity?.activityId}
                                                        style={{ marginBottom: "20px" }}
                                                    >
                                                        <div style={{ display: "flex", width: "100%" }}>
                                                            {isEventKey && (
                                                                <>
                                                                    <span
                                                                        title={activity?.title}
                                                                        className="act-ellipses gx-mr-4"
                                                                        style={{
                                                                            width: "140px"
                                                                        }}
                                                                    >
                                                                        {activity?.title}
                                                                    </span>
                                                                    <span
                                                                        title={activity?.description}
                                                                        className="act-ellipses gx-mr-4"
                                                                        style={{
                                                                            width: "140px"
                                                                        }}
                                                                    >
                                                                        {activity?.description}
                                                                    </span>
                                                                    <span
                                                                        className="gx-mr-4"
                                                                        style={{ width: "135px" }}
                                                                    >
                                                                        {dateTime}
                                                                    </span>
                                                                    {activity?.neighborhood ? (
                                                                        <span
                                                                            title={activity?.neighborhood}
                                                                            className="gx-mr-4 act-ellipses"
                                                                            style={{
                                                                                width: "135px"
                                                                            }}
                                                                        >
                                                                            {activity?.neighborhood}
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            className="gx-mr-4"
                                                                            style={{ width: "135px" }}
                                                                        >
                                                                            --
                                                                        </span>
                                                                    )}
                                                                    {activity?.type == 1 ? (
                                                                        <span style={{ width: "70px" }}>Event</span>
                                                                    ) : (
                                                                        <span style={{ width: "70px" }}>Progam</span>
                                                                    )}
                                                                    {activity?.cost ? (
                                                                        <span style={{ width: "80px" }}>
                                                                            ${activity?.cost}
                                                                        </span>
                                                                    ) : (
                                                                        <span style={{ width: "80px" }}>Free</span>
                                                                    )}

                                                                    <span
                                                                        onClick={onOpen}
                                                                        className=" gx-link"
                                                                        style={{ width: "60px" }}
                                                                        type="text"
                                                                    >
                                                                        View
                                                                    </span>
                                                                </>
                                                            )}
                                                            {isFreebieKey && (
                                                                <>
                                                                    <span
                                                                        title={activity?.content?.headline}
                                                                        className="act-ellipses gx-mr-4"
                                                                        style={{
                                                                            width: "165px"
                                                                        }}
                                                                    >
                                                                        {activity?.content?.headline}
                                                                    </span>
                                                                    {activity?.ownerName ? (
                                                                        <span
                                                                            title={activity?.ownerName}
                                                                            className="gx-mr-4 act-ellipses"
                                                                            style={{
                                                                                width: "115px"
                                                                            }}
                                                                        >
                                                                            {activity?.ownerName}
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            className="gx-mr-4"
                                                                            style={{ width: "115px" }}
                                                                        >
                                                                            --
                                                                        </span>
                                                                    )}
                                                                    <span
                                                                        title={activity?.categoryName}
                                                                        className="gx-mr-4 act-ellipses"
                                                                        style={{
                                                                            width: "145px"
                                                                        }}
                                                                    >
                                                                        {activity?.categoryName}
                                                                    </span>
                                                                    {activity?.neighborhoodName ? (
                                                                        <span
                                                                            title={activity?.neighborhoodName}
                                                                            className="gx-mr-4 act-ellipses"
                                                                            style={{
                                                                                width: "110px"
                                                                            }}
                                                                        >
                                                                            {activity?.neighborhoodName}
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            className="gx-mr-4"
                                                                            style={{ width: "110px" }}
                                                                        >
                                                                            --
                                                                        </span>
                                                                    )}
                                                                    <span style={{ width: "150px" }}>{postedDate}</span>

                                                                    <span
                                                                        onClick={onOpen}
                                                                        className=" gx-link gx-ml-3"
                                                                        style={{ width: "60px" }}
                                                                        type="text"
                                                                    >
                                                                        View
                                                                    </span>
                                                                </>
                                                            )}

                                                            {isChatKey && (
                                                                <>
                                                                    <span
                                                                        title={activity?.content?.headline}
                                                                        className="act-ellipses gx-mr-5"
                                                                        style={{ width: "160px" }}
                                                                    >
                                                                        {activity?.content?.headline}
                                                                    </span>
                                                                    <span
                                                                        title={activity?.content?.description}
                                                                        className="act-ellipses gx-mr-5"
                                                                        style={{ width: "175px" }}
                                                                    >
                                                                        {activity?.content?.description}
                                                                    </span>
                                                                    {activity?.ownerName ? (
                                                                        <span
                                                                            title={activity?.ownerName}
                                                                            className="gx-mr-5 act-ellipses"
                                                                            style={{ width: "135px" }}
                                                                        >
                                                                            {activity?.ownerName}
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            className="gx-mr-5"
                                                                            style={{ width: "135px" }}
                                                                        >
                                                                            --
                                                                        </span>
                                                                    )}
                                                                    <span
                                                                        className="gx-mr-5"
                                                                        style={{ width: "135px" }}
                                                                    >
                                                                        {postedDate}
                                                                    </span>

                                                                    <span
                                                                        onClick={onOpen}
                                                                        className="gx-link"
                                                                        style={{ width: "60px" }}
                                                                        type="text"
                                                                    >
                                                                        View
                                                                    </span>
                                                                </>
                                                            )}
                                                            {isAccessKey && (
                                                                <span className="act-ellipses">{activity?.name}</span>
                                                            )}
                                                            {isExploreKey && (
                                                                <>
                                                                    <span
                                                                        title={activity?.title}
                                                                        className="act-ellipses gx-mr-5"
                                                                        style={{ width: "150px" }}
                                                                    >
                                                                        {activity?.title}
                                                                    </span>
                                                                    <span
                                                                        title={activity?.type}
                                                                        className="act-ellipses gx-mr-5"
                                                                        style={{ width: "160px" }}
                                                                    >
                                                                        {
                                                                            CHILDREN_ACTIVITY_TYPE[activity?.type - 1]
                                                                                ?.label
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        title={activity?.category}
                                                                        className="act-ellipses gx-mr-5"
                                                                        style={{ width: "160px" }}
                                                                    >
                                                                        {activity?.category}
                                                                    </span>

                                                                    <span
                                                                        className="gx-mr-5"
                                                                        style={{ width: "135px" }}
                                                                    >
                                                                        {!activity?.datePosted
                                                                            ? "--"
                                                                            : `${formatDateToSpecificTZv2(
                                                                                  activity?.datePosted,
                                                                                  DATE_TIME_FORMAT.US_DATE_TIME_FORMAT
                                                                              )} ${activity?.datePosted && "CST"}`}
                                                                    </span>

                                                                    <span
                                                                        onClick={onOpen}
                                                                        className="gx-link"
                                                                        style={{ width: "60px" }}
                                                                        type="text"
                                                                    >
                                                                        View
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </Radio>
                                                </div>
                                            );
                                        })}
                                    </Radio.Group>
                                ) : Array.isArray(data?.list) && !data?.list?.length ? (
                                    <div style={{ height: "100px" }}>
                                        <h1 style={{ textAlign: "center" }}>
                                            {!data?.list?.length && !isLoading && payload.searchText && (
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            )}
                                        </h1>
                                    </div>
                                ) : (
                                    <div style={{ height: "100px" }}></div>
                                )}
                            </Skeleton>
                        )}

                        {!isFetching &&
                        !isLoading &&
                        data?.list?.length &&
                        data?.filteredCount > 5 &&
                        payload.searchText ? (
                            <Pagination
                                className="gx-justify-content-center"
                                total={data?.filteredCount}
                                current={payload.Offset / payload.Count + 1}
                                defaultCurrent={1}
                                hideOnSinglePage={true}
                                pageSize={payload.Count}
                                showSizeChanger={false}
                                size={"small"}
                                onChange={handlePagination}
                            />
                        ) : null}

                        <Space className="gx-mb-2 gx-w-100 gx-justify-content-end">
                            <Button onClick={handleCancel}>Cancel</Button>

                            <Button
                                type="primary"
                                onClick={handleFieldValue}
                                disabled={!Object.keys(selectedData ?? {})?.length}
                            >
                                Create Link
                            </Button>
                        </Space>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default InAppNotificationURI;
