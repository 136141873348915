import React from "react";
import { useQuery } from "react-query";
import { Checkbox, Skeleton, Row, Col } from "antd";
import { LookUpApiService } from "../../../ApiServices";
import { API_STORAGE_KEY } from "../../../constants/constant";
import notificationService from "../../../util/notification.service";

const getAccommodation = async () => {
    const { ok, data, response } = await LookUpApiService.getAccommodations();
    if (ok) {
        return data;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const AccommodationLookup = (props) => {
    const { data: accommodationData = [], isLoading } = useQuery(
        API_STORAGE_KEY.ACCOMMODATION_LOOKUP,
        getAccommodation
    );

    return (
        <Skeleton active loading={isLoading}>
            <Checkbox.Group defaultValue={props.checked} {...props}>
                <Row gutter={[16, 8]} style={{ display: "flex", flexDirection: "row" }}>
                    {accommodationData?.map((info) => {
                        return (
                            <Col className="checkbox-options">
                                <Checkbox value={info.id} key={info?.id}>
                                    {info?.name}
                                </Checkbox>
                            </Col>
                        );
                    })}
                </Row>
            </Checkbox.Group>
        </Skeleton>
    );
};
export default AccommodationLookup;
