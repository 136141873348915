import React from "react";
import { NumericFormat } from "react-number-format";
import { Form, InputNumber, Row, Col, Input, Space, Button } from "antd";

import { handleLookupChange, handleValueChange, modifyRules } from "../helper";

import "./AddNotification.less";

export const RenderChildren = ({ index: pIndex, form, getNotificationCount }) => {
    return (
        <Form.List name={[pIndex, "values"]} initialValue={[{}]}>
            {(fields, { add, remove }) => {
                return (
                    <>
                        {fields.map((field, cIndex) => {
                            return (
                                <Row justify="space-between" style={{ position: "relative" }} className="gx-flex-row">
                                    <Col span={12} className="gx-pl-0">
                                        <Form.Item
                                            require={false}
                                            rules={[{ required: true, message: "Value is required" }]}
                                            name={[field.name, "value1"]}
                                            className="gx-m-0"
                                            normalize={(value, prevValue, prevValues) => {
                                                if (!value) {
                                                    return "";
                                                } else {
                                                    return Number(value);
                                                }
                                            }}
                                        >
                                            <NumericFormat
                                                className="ant-input"
                                                allowNegative={false}
                                                onValueChange={(values) => {
                                                    handleValueChange({
                                                        pIndex,
                                                        cIndex,
                                                        form,
                                                        val: values?.value,
                                                        key: "value1",
                                                        getNotificationCount
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gx-pr-0">
                                        <Form.Item dependencies={[["rules", pIndex, "values", field.name, "value1"]]}>
                                            {({ getFieldValue }) => {
                                                const v1 = getFieldValue([
                                                    "rules",
                                                    pIndex,
                                                    "values",
                                                    field.name,
                                                    "value1"
                                                ]);

                                                return (
                                                    <Form.Item
                                                        required={false}
                                                        normalize={(value, prevValue, prevValues) => {
                                                            if (!value) {
                                                                return "";
                                                            } else {
                                                                return Number(value);
                                                            }
                                                        }}
                                                        className="gx-m-0"
                                                        rules={[{ required: true, message: "Value is required" }]}
                                                        name={[field.name, "value2"]}
                                                    >
                                                        <NumericFormat
                                                            disabled={!Boolean(v1)}
                                                            allowNegative={false}
                                                            className="ant-input"
                                                            onValueChange={(values) => {
                                                                handleValueChange({
                                                                    pIndex,
                                                                    cIndex,
                                                                    form,
                                                                    val: values?.value,
                                                                    key: "value2",
                                                                    getNotificationCount
                                                                });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                );
                                            }}
                                        </Form.Item>
                                    </Col>

                                    {cIndex !== 0 ? (
                                        <Col className="value-dlt-btn">
                                            <Button
                                                icon={<i className={` usd-delete gx-m-0`} />}
                                                size="medium"
                                                className="noirProRegular gx-fs-md gx-m-0 delete-btn"
                                                onClick={() => {
                                                    const value1 = form.getFieldValue([
                                                        "rules",
                                                        pIndex,
                                                        "values",
                                                        field.name,
                                                        "value1"
                                                    ]);
                                                    const value2 = form.getFieldValue([
                                                        "rules",
                                                        pIndex,
                                                        "values",
                                                        field.name,
                                                        "value2"
                                                    ]);
                                                    remove(field.name);
                                                    if (value1 && value2) {
                                                        getNotificationCount(modifyRules(form.getFieldValue("rules")));
                                                    }
                                                }}
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                            );
                        })}
                        <p type="text" className="gx-link add-new" onClick={() => add({})}>
                            Add new range
                        </p>
                    </>
                );
            }}
        </Form.List>
    );
};
