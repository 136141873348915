import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

import { LookUpApiService } from "../../../ApiServices";
import { API_STORAGE_KEY } from "../../../constants/constant";
import notificationService from "../../../util/notification.service";

const getParentInterestTag = async () => {
    const { ok, data, response } = await LookUpApiService.parentInterest();
    if (ok) {
        return data;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const ParentInterestLookup = (props) => {
    const { data: parentTag = [], isLoading } = useQuery(API_STORAGE_KEY.PARENT_INTEREST_LOOKUP, getParentInterestTag);

    return (
        <>
            <Select
                mode="multiple"
                disabled={isLoading}
                loading={isLoading}
                showSearch
                filterOption={(input, option) => {
                    return option.props.children.toLowerCase().includes(input.toLowerCase());
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                {...props}
            >
                {parentTag?.map((data, index) => {
                    return (
                        <Select.Option key={data?.id} value={data.id}>
                            {data.name}
                        </Select.Option>
                    );
                })}
            </Select>
        </>
    );
};
export default ParentInterestLookup;
