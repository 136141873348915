import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsedSideNav } from "appRedux/slices/Settings";

import { AUTHENTICATED_ROUTES } from "../../routes/constant";
import { ADMINISTRATOR_STATUS } from "../../constants/constantTypes";
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";

import "./index.less";

const _MAP_STATUS = {
    [ADMINISTRATOR_STATUS.ACTIVATE]: "Active",
    [ADMINISTRATOR_STATUS.INACTIVATE]: "Inactive"
};

const { Header } = Layout;
const Topbar = (props) => {
    const { width, navCollapsed, navStyle, pathname } = useSelector(({ settings }) => settings);
    const dispatch = useDispatch();
    const isMyProfileRoute = pathname.includes(AUTHENTICATED_ROUTES.MY_PROFILE);

    const getTitle = () => {
        if (isMyProfileRoute) {
            const { user } = props;
            const fullName =
                user?.firstName && user?.lastName ? user?.firstName + " " + user?.lastName : user?.firstName;
            return (
                <span>
                    {fullName}{" "}
                    {user?.status && (
                        <span
                            className={`topbar-status-badge ${
                                user?.status === ADMINISTRATOR_STATUS.ACTIVE
                                    ? "active-badge-status"
                                    : "inactive-badge-status"
                            }`}
                        >
                            {_MAP_STATUS[user?.status]}
                        </span>
                    )}
                </span>
            );
        } else {
            return props.title;
        }
    };

    return (
        <Header>
            {navStyle === NAV_STYLE_DRAWER ||
            ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ? (
                <div className="gx-linebar gx-mr-3 text-color-white">
                    <i
                        className="gx-icon-btn icon toggle-menu icon-menu gx-text-white"
                        onClick={() => {
                            dispatch(toggleCollapsedSideNav(!navCollapsed));
                        }}
                    />
                </div>
            ) : null}
            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
                <img
                    alt=""
                    width={45}
                    height={45}
                    className="gx-mr-1"
                    src={require("assets/logo/parent-pass-logo.svg")}
                />
            </Link>

            <h1 className="margin-0 noirProRegular gx-fs-xxl" style={{ color: "#212121" }}>
                {getTitle()}
            </h1>
        </Header>
    );
};

export default Topbar;
