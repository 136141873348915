import ApiService from "../util/api.service";
import { SERVICE_CONFIG_URLS } from "./config";

const AdminApiService = {
    login,
    forgetPassword,
    resetPassword,
    setPassword,
    logout,
    updateUserDetails,
    getUserDetails,
    invite,
    changePassword
};

async function login(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ADMIN.LOGIN, payload);
    return response;
}
async function logout(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ADMIN.LOGOUT, payload);
    return response;
}
async function forgetPassword(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ADMIN.FORGET_PASSWORD, payload);
    return response;
}

async function resetPassword(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ADMIN.RESET_PASSWORD, payload);
    return response;
}

async function setPassword(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ADMIN.SET_PASSWORD, payload);
    return response;
}

async function getUserDetails() {
    const response = await ApiService.get(SERVICE_CONFIG_URLS.ADMIN.GET_USER_DETAILS);
    return response;
}

async function updateUserDetails(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ADMIN.UPDATE_USER_DETAILS, payload);
    return response;
}

async function invite(payload) {
    const response = await ApiService.post(SERVICE_CONFIG_URLS.ADMIN.INVITE, payload);
    return response;
}

async function changePassword(payload) {
    const response = await ApiService.put(SERVICE_CONFIG_URLS.ADMIN.CHANGE_PASSWORD, payload);
    return response;
}

export default AdminApiService;
