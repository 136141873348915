import { TOPIC_LOOKUP_TYPE } from "../constants/constant";
import apiService from "../util/api.service";
import utilService from "../util/utils.service";
import { SERVICE_CONFIG_URLS } from "./config";

const LookUpApiService = {
    getIsds,
    getTopic,
    getAvatar,
    getTimezone,
    accessLookup,
    exploreLookup,
    getChatAvatar,
    childInterest,
    getGeneralTag,
    parentInterest,
    getNeighborhoods,
    childSpecialNeeds,
    getTransportation,
    getAccommodations,
    getFreebieCategory,
    getNotificationRules,
    getIsds,
    getRecommendConnect,
    getSearchFreebie,
    getSearchActivities,
    getSearchPosts,
    getSearchExplore,
    getSearchAccess,
    getTopEventList,
    getActivityTopics,
    getChildrenActivities,
    getCategoryMedia,
    getAuthorsLookup,
    getUsersLookup
};

async function parentInterest() {
    const url = SERVICE_CONFIG_URLS.LOOKUP.PARENT_INTEREST;
    const response = await apiService.get(url);
    return response;
}

async function getAvatar(payload) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.LOOKUP.AVATAR, payload);
    const response = await apiService.get(url);
    return response;
}

async function childInterest() {
    const url = SERVICE_CONFIG_URLS.LOOKUP.CHILD_INTEREST;
    const response = await apiService.get(url);
    return response;
}

async function childSpecialNeeds() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.CHILD_NEEDS);
    return response;
}

async function getTopic(type = TOPIC_LOOKUP_TYPE.ALL_NEIGHORHOOD) {
    const url = utilService.createDynamicUrl(SERVICE_CONFIG_URLS.LOOKUP.TOPIC, { type });
    const response = await apiService.get(url);
    return response;
}

async function getNeighborhoods() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.NEIGHBORHOODS);
    return response;
}

async function getChatAvatar() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_CHAT_AVATAR);
    return response;
}

async function getFreebieCategory() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.FREEBIE_CATEGORY);
    return response;
}

async function getTransportation() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.TRANSPORTATION);
    return response;
}

async function getAccommodations() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.ACCOMMODATION);
    return response;
}

async function getGeneralTag() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GENERAL_TAG);
    return response;
}

async function getTimezone() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.TIMEZONE);
    return response;
}

async function accessLookup() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.ACCESS);
    return response;
}

async function exploreLookup() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.EXPLORE);
    return response;
}

async function getIsds(queryParams) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.ISDS, queryParams);
    return response;
}

async function getRecommendConnect() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.RECOMMEND_CONNECT);
    return response;
}
async function getNotificationRules() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.NOTIFICATION_RULES);
    return response;
}
async function getSearchActivities(params) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_SEARCH_ACTIVITES, params);
    return response;
}
async function getSearchFreebie(params) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_SEARCH_FREEBIE, params);
    return response;
}
async function getSearchPosts(params) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_SEARCH_POSTS, params);
    return response;
}
async function getSearchExplore(params) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_SEARCH_EXPLORE, params);
    return response;
}

async function getSearchAccess(params) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_SEARCH_ACCESS, params);
    return response;
}

async function getTopEventList(params) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_TOP_FIVE_LIST, params);
    return response;
}

async function getActivityTopics(params) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_ACTIVITY_TOPIC, params);
    return response;
}

async function getChildrenActivities(params) {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_CHILDREN_ACTIVITIES, params);
    return response;
}

async function getCategoryMedia() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_CATEGORY_MEDIA);
    return response;
}

async function getAuthorsLookup() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_AUTHORS);
    return response;
}

async function getUsersLookup() {
    const response = await apiService.get(SERVICE_CONFIG_URLS.LOOKUP.GET_USERS);
    return response;
}

export default LookUpApiService;

