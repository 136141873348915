import React from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

import { LookUpApiService } from "../../../ApiServices";
import notificationService from "../../../util/notification.service";
import { API_STORAGE_KEY } from "./../../../constants/constant";

const getNotification = async () => {
    const { ok, data, response } = await LookUpApiService.getNotificationRules();
    if (ok) {
        return data;
    } else {
        notificationService.error(response.message);
        throw new Error(response.message);
    }
};

const NotificationRules = (props) => {
    const { selectedValues = [] } = props;
    const { data: NotificationRule = [], isLoading } = useQuery(API_STORAGE_KEY.NOTIFICATION_LOOKUP, getNotification);
    const filteredOptions = NotificationRule?.filter((info) => {
        return !selectedValues.includes(info?.id);
    });

    const handleChange = (value, selectedOption) => {
        if (!value) {
            return props?.onRuleChange(value);
        }
        return props?.onRuleChange(value, selectedOption?.selectedRule);
    };
    return (
        <Select
            {...props}
            showSearch
            filterOption={(input, option) => {
                return option.props.children.toLowerCase().includes(input.toLowerCase());
            }}
            allowClear
            loading={isLoading}
            disabled={isLoading}
            onChange={handleChange}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
            {NotificationRule?.map((data) => {
                return (
                    <Select.Option
                        key={data?.id}
                        value={data.id}
                        disabled={Boolean(selectedValues.includes(data.id))}
                        selectedRule={data}
                    >
                        {data?.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
export default NotificationRules;
