export enum ACTIVITY_TYPE {
    ALL,
    EVENT,
    PROGRAM
}

export enum CONTENT_TYPE {
    ALL,
    POST,
    COMMENT
}

export enum RECURRENCE_TYPE {
    NONE,
    SIMPLE,
    COMPLEX
}

export enum ACTIVITY_STATUS {
    INVALID,
    PENDING,
    ACTIVE,
    EXPIRED,
    DECLINED
}

export enum RECOMMEND_CONNECT_STATUS {
    INVALID,
    PENDING,
    APPROVED,
    REJECTED
}

export enum NOTIFICATION_STATUS {
    PENDING,
    SENT
}

export enum ANNOUNCEMENT_STATUS {
    INVALID,
    ACTIVE,
    EXPIRED
}

export enum ADMIN_ROLE {
    Administrator,
    Moderator
}

export enum ADMINISTRATOR_STATUS_ACTION {
    ACTIVATE = 500,
    INACTIVATE = 1300,
    VIEW_PROFILE = 1500,
    ACTIVE = 300,
    INACTIVE = 200
}

export enum ADMINISTRATOR_STATUS {
    INACTIVATE = 200,
    ACTIVATE = 300
}

export enum RECURRENCE_CATEGORY_TYPE {
    Access = 1,
    Explore = 2
}

export enum COST_TYPE {
    FREE = 1,
    DISCOUNT = 2
}

export enum ACTIVITY_ACTIONS {
    EDIT = 100,
    DELETE = 200,
    REPORT = 300,
    EXPIRE = 400,
    ACTIVATE = 500,
    REMOVE_CONTENT = 600,
    DISMISS = 700,
    BAN_USER = 800,
    TRANSLATE = 900,
    VIEW_CONTENT = 1000,
    APPROVED = 1100,
    REJECTED = 1200,
    DEACTIVATE = 1300,
    UN_BAN_USER = 1400,
    VIEW_PROFILE = 1500,
    REVIEW_REPORT = 1600,
    DELETE_REPORTED_ACTIVITY = 2200,
    DUBLICATE = 2500,
    DEEP_LINK = 2700
}

export enum SUGGEST_PARTNERS_ACTIONS {
    EDIT = 100,
    DELETE = 200,
    VIEW_CONTENT = 1000,
    REJECTED = 1200,
    PUBLISHED = 2600,
    DEEP_LINK = 2700
}

export enum FREEBIE_ACTIONS {
    DELETE_CATEGORY = 100,
    DISMISS = 700,
    REMOVE_CONTENT = 600,
    BAN_USER = 800,
    VIEW_CONTENT = 1000,
    TRANSLATE = 900,
    UN_BAN_USER = 1400,
    DELETE_REPORTED_FREEBIE = 2100
}

export enum RESOURCE_ACTIONS {
    DELETE_CATEGORY = 11300,
    ADD_CONFIRM = 11400,
    UPDATE_CONFIRM = 11500,
    DELETE_RESOURCE = 11600,
    DELETE_V2_CATEGORY = 11700
}
export enum CHAT_ACTIONS {
    EDIT = 100,
    DELETE = 200,
    REPORT = 300,
    EXPIRE = 400,
    ACTIVATE = 500,
    REMOVE_CONTENT = 600,
    DISMISS = 700,
    BAN_USER = 800,
    TRANSLATE = 900,
    VIEW_CONTENT = 1000,
    UN_BAN_USER = 1400,
    EDIT_TOPIC = 1700,
    DELETE_TOPIC = 1800,
    APPROVE_TOPIC = 1900,
    REJECT_TOPIC = 2000,
    DELETE_REPORTED_CHAT = 2400
}

export enum USER_STATUS_ACTIONS {
    UNVERIFIED = 100,
    VERIFIED = 200,
    GUIDELINES_ACCEPTED = 300,
    NEIGHBORHOOD_SET = 400,
    ACTIVITIES_SET = 500,
    COMPLETED = 600,
    BANNED = 700,
    DELETED = 800
}

export enum USER_STATUS {
    INACTIVE,
    PENDING,
    ACTIVE,
    BANNED
}

export enum CONTENT_VIOLATION_TYPE {
    IMAGE = 15180,
    TEXT = 15280
}

export enum REPORT_TYPE {
    NOT_FAMILY_ORIENTED = 400,
    INAPPROPRIATE = 500,
    SAFETY_CONCERN = 600,
    OTHER = 700
}

export enum GENERAL_ACTIONS {
    VIEW_CONTENT = 1000,
    EDIT = 100,
    DELETE_CATEGORY = 200
}

export enum LISTING_ACTIONS {
    EDIT = 100,
    DELETE = 200,
    VIEW = 1000,
    TRANSLATE = 900
}

export enum ANALYTICS_SECTIONS {
    EVENTS = 1,
    ACTIVITIES = 2,
    POST = 3,
    FREEBIE = 4,
    ORGANIZATION = 5,
    ARTICLE = 6,
    ANNOUNCEMENT = 7,
    CRIME = 8,
    HOTLINE = 9,
    TOP_5 = 10,
    FIND = 11,
    ACCESS = 12
}

export enum DASHBOARD_DATE_TYPE {
    ALL_TIME,
    WEEKLY,
    MONTHLY
}
export enum REPORT_DATE_TYPE {
    TODAY,
    YESTERDAY,
    THIS_WEEK,
    LAST_WEEK,
    THIS_MONTH,
    LAST_MONTH,
    Q1_THIS_YEAR,
    Q2_THIS_YEAR,
    Q3_THIS_YEAR,
    Q4_THIS_YEAR,
    Q1_LAST_YEAR,
    Q2_LAST_YEAR,
    Q3_LAST_YEAR,
    Q4_LAST_YEAR,
    THIS_YEAR,
    LAST_YEAR,
    ALL_TIME,
    CUSTOM_RANGE
}

export enum REPORT_EVENT_TYPE {
    SERIES = 1,
    SINGLE = 2
}

export enum CHAT_CONTENT_TYPE {
    POST = 1,
    COMMENT = 2
}

export enum NOTIFICATION_ACTIONS {
    EDIT = 100,
    DELETE = 200,
    VIEW = 1000
}

export enum SELECTION_TYPE {
    MULTIPLE_SELECT = 1,
    NUMBER_SELECT = 2,
    MULTIPLE_NUMBER_SELECT = 3
}

export enum RULE_TYPE {
    NUMBER_OF_CHILDREN = 1,
    AGE_OF_CHILDREN = 2,
    NEIGHBOURHOOD = 3,
    ISD = 4,
    CONNECTED_TYPE = 5,
    PARENT_INTEREST = 6,
    CHILD_INTEREST = 7,
    SPECIAL_NEED = 8,
    COOK_CHILDREN = 9
}

export enum LANGUAGE_TYPE {
    SINGLE = 1,
    BOTH = 2
}

export enum CONNECTED_TYPE {
    EMAIL = 1,
    PHONE = 2,
    GUEST = 3,
    EXTERNAL = 4,
    USER = 5
}

export enum CONDITION_TYPE {
    GREATER_THAN = 1,
    GREATER_THAN_OR_EQUAL = 2,
    LESS_THAN = 3,
    LESS_THAN_OR_EQUAL = 4,
    EQUAL = 5,
    NOT_EQUAL = 6,
    BETWEEN = 7,
    NOT_BETWEEN = 8
}

export enum REDIRECTION_TYPE {
    NONE = 0,
    FREEBIE = 1,
    ACTIVITY = 2,
    ACCESS = 3,
    EXPLORE = 4,
    FIND = 5,
    RECOMMEND_CONNECT = 6,
    GET = 7,
    CALL = 8,
    FAMILY_NAVIGATOR = 9,
    CHAT = 10,
    COOK_CHILDREN_HEALTH_PLAN = 11,
    SETTING = 12,
    EDUCATION_SUPPORT = 13,
    TOOTRIS = 14,
    SCHOOL_A_HOOP = 15
}

export enum CONDITIONAL_NOTIFICATION_TYPE {
    YES = 1,
    NO = 2
}

export enum CC_TYPE {
    CHIP = 1200,
    STAR = 1300,
    STAR_KIDS = 1400
}

export enum ACCESS_TYPE {
    PDF = 1,
    LINK = 2
}

export enum AGE_GROUPS {
    ANY,
    BABIES,
    TODDLER,
    ELEMENTARY_SCHOOL,
    MIDDLE_SCHOOL,
    HIGH_SCHOOL
}

export enum MEDIA_TYPE {
    OTHER,
    IMAGE,
    PROFILE_IMAGE,
    AVATAR,
    ASSET,
    VIDEO
}
