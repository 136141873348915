import React, { useState } from "react";
import { Col, Row, Card, Progress, Skeleton } from "antd";

export default function EstimateTarget(props) {
    const { data = {}, form } = props;
    const rules = form.getFieldValue("rules");
    return (
        <div className="gx-main-content-wrapper gx-pb-0 ">
            <Card title="Estimated target size" className="gx-mb-3">
                <Row className="gx-m-0 gx-mb-4 gx-flex-row">
                    <Col span={24} className="gx-pl-0 gx-pr-0">
                        <h4>
                            <span style={{ color: "#96c762" }}>{data?.count ?? 0}</span> users will recieve this
                            notification
                        </h4>
                        <Skeleton className="progress-loader" paragraph={false} active loading={props?.loading}>
                            <Progress
                                status={"exception"}
                                showInfo={false}
                                percent={data?.percentage ?? 0}
                                strokeWidth={"15px"}
                                strokeColor={"#96c762"}
                            />
                        </Skeleton>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}
