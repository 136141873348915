import { AUTHENTICATED_ROUTES } from "../routes/constant";

export const ROLE_DEFAULT_ROUTE = {
    Admin: AUTHENTICATED_ROUTES.DASHBOARD,
    User: AUTHENTICATED_ROUTES.DASHBOARD
};

export const ROLES = {
    GlobalAdmin: "GlobalAdmin",
    User: "User",
    RegionalAdmin: "RegionalAdmin"
};

export const MAIN_SIDE_BAR_OPTIONS = {
    Admin: [
        {
            text: "Dashboard",
            linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
            selectedOptionKey: "dashboard",
            icon: "usd-dashboard"
        },
        {
            text: "Chat",
            linkTo: AUTHENTICATED_ROUTES.CHAT,
            selectedOptionKey: "chat",
            icon: "usd-chat",
            subMenu: [
                {
                    text: "Topics",
                    linkTo: AUTHENTICATED_ROUTES.CHAT_MANAGEMENT,
                    selectedOptionKey: "chat-management"
                },
                {
                    text: "Reported Content",
                    linkTo: AUTHENTICATED_ROUTES.REPORTED_CONTENT,
                    selectedOptionKey: "reported-content"
                }
            ]
        },
        {
            text: "Events",
            linkTo: AUTHENTICATED_ROUTES.EVENTS,
            selectedOptionKey: "activities-1",
            icon: "usd-activities",
            subMenu: [
                {
                    text: "All Events",
                    linkTo: AUTHENTICATED_ROUTES.EVENTS,
                    selectedOptionKey: "events"
                },
                {
                    text: "Reported Events",
                    linkTo: AUTHENTICATED_ROUTES.REPORTED_EVENTS,
                    selectedOptionKey: "reported-events"
                },
                {
                    text: "Top Five",
                    linkTo: AUTHENTICATED_ROUTES.TOP_FIVE,
                    selectedOptionKey: "top-five"
                },
                {
                    text: "Top Five by User",
                    linkTo: AUTHENTICATED_ROUTES.TOP_FIVE_BY_USER,
                    selectedOptionKey: "top-five-by-user"
                }
            ]
        },
        {
            text: "Freebies",
            linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
            selectedOptionKey: "freebies",
            icon: "usd-freebies",
            subMenu: [
                {
                    text: "Categories",
                    linkTo: AUTHENTICATED_ROUTES.FREEBIE_CATEGORY,
                    selectedOptionKey: "freebie-category"
                },
                {
                    text: "Reported Freebies",
                    linkTo: AUTHENTICATED_ROUTES.REPORTED_FREEBIES,
                    selectedOptionKey: "reported-freebies"
                }
            ]
        },
        {
            text: "Access",
            linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
            selectedOptionKey: "access-1",
            icon: "usd-category",
            subMenu: [
                {
                    text: "Access Category",
                    linkTo: AUTHENTICATED_ROUTES.ACCESS_CATEGORIES,
                    selectedOptionKey: "access-categories"
                },
                {
                    text: "Access",
                    linkTo: AUTHENTICATED_ROUTES.ACCESS,
                    selectedOptionKey: "access"
                }
            ]
        },
        {
            text: "Find",
            linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
            selectedOptionKey: "find",
            icon: "usd-category", // replace later with actual find icon
            subMenu: [
                {
                    text: "Education Support Category",
                    linkTo: AUTHENTICATED_ROUTES.EDUCATION_SUPPORT_CATEGORY,
                    selectedOptionKey: "education-support-categories"
                },
                {
                    text: "Education Support",
                    linkTo: AUTHENTICATED_ROUTES.EDUCATION_SUPPORT,
                    selectedOptionKey: "education-support"
                }
            ]
        },

        {
            text: "Explore",
            linkTo: AUTHENTICATED_ROUTES.RESOURCES,
            selectedOptionKey: "resources",
            icon: "usd-toys",
            subMenu: [
                {
                    text: "Explore Categories",
                    linkTo: AUTHENTICATED_ROUTES.EXPLORE_CATEGORIES,
                    selectedOptionKey: "explore-categories"
                },
                {
                    text: "Explore",
                    linkTo: AUTHENTICATED_ROUTES.EXPLORE,
                    selectedOptionKey: "explore"
                }
            ]
        },
        {
            text: "Connect",
            linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
            selectedOptionKey: "recommend-connect",
            icon: "usd-connect",
            subMenu: [
                {
                    text: "Connect Category",
                    linkTo: AUTHENTICATED_ROUTES.CONNECT_CATEGORY,
                    selectedOptionKey: "connect-category"
                },
                {
                    text: "Organizations",
                    linkTo: AUTHENTICATED_ROUTES.ORGANIZATIONS,
                    selectedOptionKey: "organizations"
                }
            ]
        },
        {
            text: "Announcements",
            linkTo: AUTHENTICATED_ROUTES.ANNOUNCEMENT,
            selectedOptionKey: "announcement",
            icon: "usd-announcements"
        },
        {
            text: "Articles",
            linkTo: AUTHENTICATED_ROUTES.ARTICLES,
            selectedOptionKey: "articles",
            icon: "usd-articles"
        },
        {
            text: "Push Management",
            linkTo: AUTHENTICATED_ROUTES.PUSH_MANAGEMENT,
            selectedOptionKey: "push-management",
            icon: "usd-push"
        },
        {
            text: "Users",
            linkTo: AUTHENTICATED_ROUTES.USERS,
            selectedOptionKey: "users",
            icon: "usd-users"
        },
        {
            text: "Administrators",
            linkTo: AUTHENTICATED_ROUTES.ADMINISTRATORS,
            selectedOptionKey: "administrators",
            icon: "usd-administrators"
        },
        {
            text: "Reports",
            linkTo: AUTHENTICATED_ROUTES.REPORTS,
            selectedOptionKey: "reports",
            icon: "usd-reports"
        },
        {
            text: "My Profile",
            linkTo: AUTHENTICATED_ROUTES.MY_PROFILE,
            selectedOptionKey: "my-profile",
            icon: "usd-account"
        }
    ]
};
